import React from "react"
import { graphql } from "gatsby"
import {
  Section,
  SectionHeading,
  SectionLedeDiv,
} from "../../../styledComponents/section"
import Layout from "../../../components/layout"

const Heading = ({ level = 1, location, children }) => (
  <SectionHeading as={`h${level}`} level={level} location={location}>
    {children}
  </SectionHeading>
)

const Thankyou = ({ data, location }) => {

  return (
    <>
    <Layout displayNavBorder location={location}>
      <Section>
        <Heading level={1}>
          Form complete
        </Heading>
        <SectionLedeDiv>
        <p>Thank you for filling in the form.</p>
        </SectionLedeDiv>       
      </Section>
    </Layout>
    </>
  )
}

export default Thankyou

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    wpPage(slug: { eq: "thank-you" }) {
      title
      content
    }
  }
`
